#documentatie {
	.slider-controls-top {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.body-link.body-link.body-link {
			margin: 0;
			display: inline-block;
		}
	}
	.slider-controls:not(.slider-controls-top) {
		.body-link {
			display: block;
			background-color: transparent;
			border: none;
			margin-left: auto;
			cursor: pointer;
			&:focus { 
				outline: none;
			}
		}
	}
	.g-title {
		margin-bottom: 0;
	}
	.header {
		margin-bottom: 30px;
	}
	.body-link {
		font-size: .95rem;
	}
	.doc-item {
		overflow: hidden;
		display: flex;
		min-height: 80px;
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		padding: 0;
		background-color: #fafafa;
		color: $gray-1;
		text-decoration: none;		
		&:nth-child(2n) {
			border-left: 1px solid rgba(0, 0, 0, 0.05);	
			border-right: 1px solid rgba(0, 0, 0, 0.05);
			
		}
		&:nth-last-child(1), &:nth-last-child(2) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		} 
		.btn {
			padding: 12px 15px;
			text-transform: none;
			cursor: pointer;
		}
		img {
			width: auto;
			height: auto;
		}
		div:nth-child(1) {
			flex: 0 0 70px;
		}
		div:nth-child(2) {
			flex: 0 0 calc(100% - 210px);
			span {
				text-align: left;
				padding-left: 10px;
			}
		}
		div:nth-child(3) {
			flex: 0 0 140px;
		}
		.align-center span {
			text-align: center;
		}
	}
	@media( min-width: 992px ) {
		.slider-controls {
			padding-top: 40px;
			.body-link {
				padding-left: 0;
			}
		}
		.slider-controls-top .slider-controls {
			padding-top: 0;
		}
		.doc-item {
			transition: all 300ms ease-out;
			.btn {
				position: relative;
				left: 50px;
				z-index: -1;
				opacity: 0;
				transition: all 300ms ease-in-out;
			}
			&:hover {
				background-color: #f2f2f2;
				.btn {
					z-index: 1;
					opacity: 1;
					left: 0;
				}
			}
		}
	}
	@media( max-width: 992px ) {
		.slider-controls {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

@media( min-width: 768px ) {
	.slider-controls.left {
		padding-top: 30px;
		.body-link {
			padding-left : 0;
		}	
	} 
}

@media ( max-width: 991px ) and ( min-width: 768px ) {
	.slider-controls.left {
		.body-link {
			margin-right: 0;
			display: block;
			width: 100%;
			text-align: right;
		}	
	} 
} 

@media ( max-width: 767px ) {
	.slider-controls.dropdown {
		background-color: #fafafa;
	}
}

.doc-item {
	display: none !important;
}


.show.show {
	display: flex !important;
}

@media( max-width: 480px ) {
	.doc-item {
		flex-direction: column;
		padding: 20px 10px !important;
		>div {
			flex: 0 0 auto !important;
			span {
				text-align: center !important;
			}
			&:nth-child(2) {
				padding: 15px;
			}
		}
	}
}