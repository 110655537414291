#team {
	overflow: hidden;
	padding: 90px 0;
	.g-title {
		margin-bottom: 100px;
	}
	img {
		max-width: 100%;
		-webkit-filter: grayscale(100%); 
		filter: grayscale(100%);
		transition: all 120ms ease-in-out;
	}
	.product-item {
		margin-left: auto;
		transform: translateX(50%);
		width: 250px;
		z-index: 4;
		max-width: 75%;
		margin-bottom: 60px;
		@media( max-width: 1319px ) {
			transform: translateX(0);
		}
		.labels {
			text-align: right;
			right: 50%;
			top: calc( 100% - 43px );
			transform: translateX(0);
			@media( max-width: 500px ) {
				right: 37%;
			}
			.label-name {
				margin-bottom: 0;
				transform: translateX(0);
				span {
					margin-left: auto;
				}
			}
			.btn-red {
				text-transform: none;
				font-size: 12px;
				padding: 5px 15px;
				font-weight: 300;
				background-color: $red !important;
			}
		}
		img:hover {
			-webkit-filter: none; 
			filter: none;
		}

		@media(max-width: 768px) {
			transform: none;
			margin: 0 auto 50px auto;
		}
	}
	@media( max-width: 991px ) {	
		padding: 30px 0;
		.g-title {
			margin-bottom: 40px;
		}
	}
}

@media( max-width: 991px ) {
	.justify-content-lg-center {
		justify-content: center;
	}
}