.footer {
	font-size: 14px;
	line-height: 1.4;
	color: #666;
	a {
		color: darken($gray-4, 15);
		&:hover {
			color: $red;
			text-decoration: none;
		}
	}
	p {
		margin-bottom: 0;
		color: darken($gray-4, 15);
	}
	.footer-top {
		padding: 60px 0 35px 0;
		color: #666;
		div, p, a, a:hover {
			color: #666;
		}
	}

	.contact-details {
		padding: 10px 18px 10px 30px;
		line-height: 18px;
	}

	.about-company {
		padding:0 30px;
		.logo-footer {
			padding-bottom: 72px;
			display: block;
			img {
				max-width: 100%;
				width: 180px;
			}
		}
	}
	.footer-middle {
		.socialicon {
			display: none;
			@media (max-width: 991px)  {
				display: block;	
			}
		}
	}
	.socialicon {
		padding-top: 20px;
		padding-left: 30px;
		a {

			font-size:22px;
			display: inline-block;
			padding-right: 22px;
		}
	}
	.titlehead {
		padding:30px 20px 0 30px;
		display: block;
		text-transform: uppercase;
		color: #666;
		font-size: 14px;
		font-family: $roboto;
	}
	.details {
		padding:26px 20px 0 30px;
		a {
			display: inline-block;
			transition: all 80ms ease-in-out;
		}
	}
	.politici {
		padding: 24px 0px 0px 30px;
		color: #d3d3d5;
	}
	form {
		padding:0 1px;
		input::placeholder{
			color: darken(#d3d3d5, 5);
		}

		input[type=text] {
			width: 100%;
			background-color: transparent;
			padding: 13px 12px 16px 30px;
			border: none;
			box-sizing: border-box;
			border-bottom: 1px solid #ececec;
		}

		textarea:focus, input:focus{
			outline: none;
		}
		
		input[type=submit] {
			background-color: #e73843;
			color: white;
			font-size: 13px;
			font-weight:bold;
			letter-spacing:1px;
			padding: 13px 16px;
			border:none;
			width:100%;
		}
		input[type=submit]:hover {
			opacity: 0.8;
		}
	}

	.footer-bottom {
		.socialicon {
			@media (max-width: 991px)  {
				display: none;
			}
		}
	}
}

[class^="icon-"], [class*=" icon-"] {
	color: $gray-1;
}

@media( min-width: 992px ) {
	.footer-middle {
		position: relative;
		.row >div {
			padding-top: 45px;
		}
		&:before {
			position: absolute;
			content:"";
			width:70%;
			left:20%;
			height: calc(100% + 100px);
			background-color: rgba( 0,0,0,0.025)
		}
	}
}

@media( min-width:1202px ) and (max-width: 1320px) { 
	.footer-middle {
		&:before {
			width: 80%;
		}
	}
}

.alert {
	border: 1px solid #eee;
	box-shadow: 0 0 3px 0 #aaa;
	padding: 15px;
	margin-left: 20px;
}