 .wow {
   visibility: hidden;
 }
 .wrap-center {
   display: flex;
   justify-content: center;
   height: 100%;
   overflow: hidden;
 }

 .slider-controls-list {
   align-self: center;
   display: flex;
   flex-direction: column;
 }

 .slider-control {
   font-weight: 400;
   color: $gray-2;
   letter-spacing: 1px;
   font-size: $font-size-5;
   padding: 4px 15px;
   position: relative;
   cursor: pointer;
   &:before {
    content: "";
    position: absolute;
    transition: all 300ms ease-in-out;
    width: 200px;
    height: 1px;
    background-color: lighten($gray-2, 10);
    top: 50%;
    transform: translateX(-100%);
    right: calc(100%  + 15px);
  }
  &.tns-nav-active {
    color: $gray-1;
    font-weight: bold;
    &:before {
     transform: translateX(0);		
   }
 }
}

.slider-text {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: stretch;
}

.slider-separator {
  flex: 0 0 1px;
  height: 150px;
  background-color: white;
  transform: scale(1,0);
};

.slider-title, .slider-subtitle {
  color: white;
  font-family: $proxima;
  padding: 20px 30px 40px 30px;
  align-self: center;
  letter-spacing: .9px;
  font-weight: bold;
}

.slider-title {
  font-size: 66px;
  line-height: 4.4rem;
  text-align: right;
  flex: 0 0 28.55%;
}

.slider-subtitle {
  font-size: 26px;
  line-height: 26px;
  padding-top: 20px;
}


@media(max-width: 1319px) {
	.slider-control {
		padding: 1.5px 0;
	}
	.slider-title {
		font-size: 50px;
	}
	.slider-separator {
		height: 90px;
	}
	.slider-subtitle {
		font-size: 21px;
		line-height: 23px
	}
	.slider-title, .slider-subtitle {
		padding: 10px 30px 10px 30px;
	}
}

@media(max-width: 767px) {
	.slider-text {
		flex-direction: column;
	}	
	.slider-title, .slider-subtitle {
		text-align: center;
	}
	.slider-separator {
		width: 180px;
		margin: 0 auto;
	}
	.slider-title {
		padding: 0 15px;
	}
	.slider-subtitle {
		font-size: 1.2rem;
		padding-bottom: 30px;
	}
	.slider-controls-list {
		overflow: hidden;
		flex-direction: row;
	}
	.slider-control {
		margin: 0 8px 32px 8px;
	}
	.slider-control:before {
		left: 50%;
		top: 100%;
		width: 1px;
		height: 40px;
		transform: translateY(100%);
	}
}

.slider-full {
	.slider-body {
   height: calc(100vh - 250px);
   min-height: 472px;
   max-height: 1200px;
   overflow: hidden;
   @media( max-width: 767px ) {
     height: 300px;
     min-height: 0;
   }
   &:not(.tns-item) {
    display: none;
  }
}
}

.slider-full {
	
  .tns-fadeIn {
    position:relative;
    transform-origin:top center;
    .slider-title {
      overflow: hidden;
      span {
        display: block;
        animation-name: fadeInRight;
        animation-fill-mode: both;
        animation-duration: 1s;
        opacity: 0;
        animation-delay: 2s;
      }
    }

    .slider-subtitle {
      overflow: hidden;
      span {
        display: block;
        animation-name: fadeInLeft;
        animation-fill-mode: both;
        animation-duration: 1s;
        opacity: 0;
        animation-delay: 2s;
      }
    }

    .slider-separator {
      animation-name: growY;
      animation-fill-mode: both;
      animation-duration: .3s;
      animation-delay: 1.7s;
    }

    .img {
      animation-name: photoenlarge;
      animation-duration: 1.3s;
      animation-fill-mode: both;
      clip-path: inset(0% 100% 0% 0%);
      display:block;
      animation-delay: 1.5s;
    }
    &:before {
      animation-duration: 1.5s;
      animation-fill-mode: both;
      background-color: #eee;
      width:100%;
      height: 100%;
      content:"";
      position: absolute;
      animation-name: enlarge;
      transform-origin:top center;
      transform: matrix(0.1, 0, 0, 0.01, 0, 0);
    }

  }

}





@keyframes enlarge {
  from {
    transform: matrix(0.1, 0, 0, 0.01, 0, 0);
  }
  50% {
    transform: matrix(0.1, 0, 0, 1, 0, 0)
  }
  to {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes shrink {
  from {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  50% {
    transform: matrix(0.1, 0, 0, 1, 0, 0)
  }
  to {
    transform: matrix(0.1, 0, 0, 0.01, 0, 0);
  }
}

@keyframes photoenlarge {
  from {
    clip-path: inset(0% 100% 0% 0%);
  }
  to {
    clip-path: inset(0% 0% 0% 0%);
  }
}


@keyframes growY {
  from {
   transform: scale(1,0);
 }
 to {
  transform: scale(1,1);
}
}

