.breadcrumb {
	display: flex;
	padding: 10px 15px 10px 0;
	margin: 0;
	justify-content: flex-end;
	li {
		list-style-type: none;
		text-transform: uppercase;
		font-weight: bold;
		a {
			text-decoration: none;
			color: lighten($gray-1, 5);
			font-size: .75rem;
		}
		&:after {
			content: ">";
			padding: 0 5px;
			font-size: .8rem;
		}
	}
	li:first-child a {
		color: darken($gray-1, 10);
	}
	li:last-child {
		color: $red;
		font-size: .75rem;
		&:after {
			display: none;
		}
	}
}