#contact {
	.g-title.g-title {
		margin: 0;
	}
	.map {
		margin-top: 50px;
	}
	.contact-info a {
		color: #79787d;
	}
	.title, .subtitle {
		font-weight: bold;
		font-family: $proxima;
		font-size: 1.15rem;

	}
	.subtitle {
		text-transform: uppercase;
	}
	.contact-info div:not(.title) {
		font-size: .9rem;
		line-height: 1.2rem;
	}
	.contact-heading {
		margin-top: 40px;
		margin-bottom: 40px;
		display: flex;
		justify-content: space-between;
		.breadcrumb li {
			align-self: flex-end
		}
	}
}

.contact-form {
	overflow: hidden;

	button {
		font-weight: bold;
		width: 50%;
		float: right;
	}
	input, textarea {
		background-color: transparent;
		font-weight: bold;
		color: $gray-1;
		font-weight: 300;
		width: 100%;
		border: none;
		padding: 13px 33px;
		border-bottom: 1px solid lighten($gray-2, 10);
		caret-color: lighten($gray-1, 40);
		transition: 200ms all;
		&::placeholder {
			color: lighten($gray-1, 20);
			font-weight: 300;
			font-size: .9rem;
		}
		&:focus {
			outline: none;
			border-color: $red;
		}
	}
	textarea {
		display: block;
	}
}

@media(max-width: 576px ) {
	.contact-form button {
		width: 100%;
	}
}
