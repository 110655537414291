@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot?wgli43');
  src:  url('/fonts/icomoon.eot?wgli43#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?wgli43') format('truetype'),
    url('/fonts/icomoon.woff?wgli43') format('woff'),
    url('/fonts/icomoon.svg?wgli43#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-volume-mute:before {
  content: "\e904";
}
.icon-volume-medium:before {
  content: "\e905";
}
.icon-pause:before {
  content: "\e906";
}
.icon-play:before {
  content: "\e907";
}
.icon-whatsapp:before {
  content: "\e908";
}
.icon-angle-down:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-pinterest:before {
  content: "\e903";
}
