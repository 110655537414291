.text {
	max-width: 300px;
	padding: 8px 25px 40px 25px;
	&.fade {
		color: lighten($gray-1, 35);
	}
}

#benefits, #catalogs {
	.icons {
		padding: 47px 0 40px 0;
		i {
			font-size: 22px;
			&.icon-pinterest {
				font-size: 24px;
			}
		}
		a {
			margin: 0 12px;
			color: $gray-1;
			transition: all 120ms ease-in-out;
			&:hover {
				color: darken($gray-1, 20);
				text-decoration: none;
			}
			&:last-child {
				margin-right: 0;				
			}
			&:first-child {
				margin-left: 0;				
			}
		}
	}
	.title {
		font-family: $proxima;
		font-size: 1.25rem;
		font-weight: bold;
		margin-bottom: 8px;
	}
}

@media(max-width: 1319px) {
	#benefits .icons {
		padding: 21px 15px 21px 15px;
	}
	.text {
		max-width: none;
		padding: 8px 25px 25px 25px;

	}
}

@media(max-width: 767px) {
	.text {
		max-width: 400px;
	}
}

@media(max-width: 575px) {
	#benefits {
		.ml-auto {
			margin-left: 0;
		}
		.text {
			text-align: center;
			margin: 0 auto;
		}
	}
}