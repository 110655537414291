.demand-offer {
	padding: 110px 0;
}

.demand-item {
	display: flex;
	max-width: 780px;
	background-color: #d4d2d2;
}
.demand-text {
	padding: 17px 30px;
	color: white;
	font-size: $font-size-3;
	font-weight: bold;
}

.demand-btn {
	flex: 0 0 200px;
	font-size: $font-size-5;
	line-height: 14px;
	text-transform: uppercase;
	border: none;
	cursor: pointer;
	&:focus {
		outline: none;
	}
}

@media( max-width: 600px ) {
	.demand-item {
		display: block;
		text-align: center;
		.demand-btn {
			padding: 18px 25px;
			width: 100%;
		}
		.demand-text {
			padding: 20px 30px;
		}
	}
}

@media( max-width: 991px ) {
	.demand-offer {
		padding: 50px 0;
	}
}

@media(max-width: 1319px) {
	.related-products {
		.body-link {
			display: inline-block;
			margin-right: auto;
			float: none;
			margin-left: 0;
		}
		.text-right {
			text-align: left !important;
		}
	}
}