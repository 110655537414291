.s-slider {
	position: relative;
	margin-top: 28px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	img {
		width: 88%;
		margin: auto;
		max-width: 92%;
		margin-bottom: 30px;
		transition: all .3s ease-out;
		&.sm {
			width: 85%;
		}
	}

	.info {
		display: flex;
		justify-content: center;
	}
	.g-subtitle {
		max-width: 150px;
	}
	&:hover img {
		transform: scale(1.08);
	}
}

.g-btn {
	padding: 8px 17px;
	border: 1px solid $gray-1;
	text-transform: uppercase;
	color: $gray-1;
	transition: all 120ms ease-in-out;
	font-size: $font-size-5;
	&:hover {
		color: white;
		border-color: $red;
		background-color: $red;
		text-decoration: none;
	}
}

#solutii {
	overflow: hidden;
}

#solutii .g-btn {
	display: inline-block;
	margin-top: 9px;
	margin-left: 27px;
}

@media(min-width: 1320px) {
	.s-slider:nth-child(1) , .s-slider:nth-child(2) {
		.info .text {
			flex: 0 0 73%;
		}
	} 
	.s-slider:nth-child(2) img {
		margin-bottom: 60px;
	}
}

@media(max-width: 1319px) {
	.s-slider.s-slider img {
		width: 500px;
		margin: 0 auto 30px auto;
	}
}

@media(max-width: 1319px) {
	.s-slider .text{
		padding: 8px 0 25px 0;
	}
	#solutii .g-btn {
		margin-left: 10px;
	}
}


#solutii {
	position: relative;
	.soare {
		position: absolute;
		top:50px;
		left:65%;
		width:192px;
		
	}
}

@media( max-width: 991px ) {
	#solutii .soare {
		top: 0;
	}
}

.doc-item.empty {
	flex: 0 0 100%;
	padding: 25px !important;
	background-color: transparent !important;
	font-size: 1.4rem;
	text-align: center;
	height: auto !important;
	justify-content: center;
}