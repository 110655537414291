.product-title {
	color: $red;
	font-size: 22px;
	padding: 30px 30px 45px 30px;
	text-transform: uppercase;
	@media( max-width: 991px ) {
		padding: 30px 30px 10px 30px;
	}
}

.slider-image {
	position: relative;
	img {
		width: 100%;
		max-width: 100%;
	}
	.label-name {
		position: absolute;
		top: 12%;
		right: calc(100% - 80px);
		width: 240px;
	}
}

.controls-item {
	padding: 25px 55px;
	position: relative;
	width: 182px;
	text-align: center;
	@media ( min-width: 1319px ) {
		margin-left: auto;		
	}
	span {
		align-self: center;
	}
	img {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		height: 80px;
	}
	.name {
		position: absolute;
		top: 50%;
		transform: translateY(-50%) rotate(-90deg);
		text-transform: uppercase;
		font-size: 14px;
		color: $red;
		right: 150px;
		transition: all ease-out .3s;
		opacity: 0;
	}
	&.active, &:hover {
		img {
			-webkit-filter: grayscale(0%);
			filter: grayscale(0%);
		}
		.name {
			opacity: 1;
		}
	}
}

ul.unstyled {
	padding: 0;
	li {
		list-style-type: none;
	}
}

ul.spaced li {
	margin-bottom: 10px;
}

ul.dash li {
	padding-left: 20px;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 10px;
		width: 12px;
		height: 1px;
		background-color: $gray-1;
	}
}

#product .title {
	text-transform: uppercase;
	margin-bottom : 5px;
}

#product .bar-button {
	margin-bottom: 10px;
}

#product .demand-offer {
	padding-top: 70px;
}

.product-specs .images {
	display: flex;
	.image-item {
		text-align: center;
		padding: 0 10px;
		flex: 0 0 33.3333%;
		max-width: 33.3333%;
		@media ( max-width: 1319px ) {
			max-width: 90px;
		}
		img {
			transition: all ease-out .3s;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		.text {
			transition: all ease-out .3s;
			opacity: 0;
			padding: 10px 0;
			line-height: 1rem;
		}
		&.active, &:hover {
			img {
				-webkit-filter: grayscale(0%);
				filter: grayscale(0%);
			}
			.text {
				opacity: 1;
				color: lighten($gray-1, 30);
			}
		}
	}
}

@media(min-width: 1320px) {
	.product-description {
		max-width: 560px;
	}
	.img-controls {
		padding-top: 140px;
	}
}

@media(max-width: 1576px) and (min-width: 1320px) {
	.slider-image .label-name {
		top: 5%;
	} 
	.img-controls {
		padding-top: 70px;
	}
}

@media (max-width: 1319px) {
	.slider-controls {
		z-index: -1;
	}
	.single-controls {
		order: -1;
	}
	.control-items-col {
		order: 2;
	}
	.img-controls {
		justify-content: center;
		display: flex;
		.controls-item {
			padding: 20px 15px 45px 15px;
			flex: 0 0 100px;
			text-align: center;
			.name {
				transform: none;
				top: auto;
				bottom: 10px;
				left: 0;
				right: 0;
				text-align: center;
			}
		}
	}
}

@media (max-width: 991px) {
	.slider-image .label-name {
		right: auto;
		left: 0;
	}
}

@media (max-width: 600px) {
	.img-controls {
		flex-wrap: wrap;
	}
	.controls-item.controls-item {
		flex: 0 0 75px;
		max-width: 75px;
		padding: 20px 5px 45px 5px;
		img {
			max-width: 90%;
			max-height: 60px;
		}
	}
	.bar-button {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
