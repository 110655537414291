#project {
	.project-img img {
		width: 100%;
		height: auto;
	}
	.g-title {
		margin-bottom: 20px;
	}
	@media( max-width: 991px ) {
		.g-title {
			margin-bottom: 15px;
			line-height: 1;
		}
	}
}