.dropdown-trigger {
	display: none;
}
@media( max-width: 767px ) {
	.dropdown .body-link.active {
		display: none !important;
	}
	.controls-dropdown {
		position: relative;
		width: 100%;
		.body-link {
			margin: 0;
		}
		&.body-link {
			margin-right: 0;
		}
		&.active {
			.slider-controls.dropdown {
				top: 45px;
				opacity: 1;
				z-index: 1;
			}
		}

	}
	.dropdown-trigger {
		display: block;
		text-align: center;
		width: calc(100% - 18px);	
		cursor: pointer;
		padding: 10px 0 10px 33px;
		transition: all ease-out .2s;
		background-color: #fafafa;
		color: #79787d;
		&.body-link {
			margin-right: 0;
		}
		i {
			transition: all ease-out .2s;
			font-size: 1.3rem;
			position: relative;
			top: 3px;
		}
	}
	.slider-controls.dropdown {
		padding: 5px 10px !important;
		display: block !important;
		text-align: center;
		position: absolute;
		width: 100%;
		top: 80px;
		left: 0;
		right: 0;
		opacity: 0;
		transition: all 200ms ease-out;
		.body-link {
			background-color: #fafafa;
			display: block;
			width: 100%;
		}
	}
}

.nav-dropdown {
	display: inline-block;
	position: relative;
	.dropdown-body {
		opacity: 0;
		position: absolute;
		top: calc(100% + 30px);
		width: 180px;
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.05);
		padding: 15px 10px;
		text-align: left;
		left: 50%;
		transform: translateX(-50%);
		transition: all .2s ease-in-out;
		display: none;
	}
	&:hover {
		.dropdown-body {
			display: block;
			opacity: 1;
			top: 100%;
			animation-name: dropdownAnimation;
			animation-duration: .2s;
		}
	}
}

@keyframes dropdownAnimation {
	0% {opacity: 0;
		top: calc(100% + 30px);
	}
	100% {opacity: 1;
		top: 100%;}
	}