#shop {
	.g-title {
		margin-bottom: 25px;
	}
	.product-item {
		margin-bottom: 30px;
		height: calc( 100% - 30px );
		> div:first-child {
			height: 100%;
			overflow: hidden;
		}
		img {
			min-width: 100%;
			min-height: 100%;
			height: 100% !important;
			width: 100% !important;
		}
	}
	@media( min-width: 992px ) {
		.slider-controls {
		}
		.controls-dropdown.controls-dropdown { 
			padding-right: 0;
			margin-right: 0;
		}
		.controls-dropdown.controls-dropdown, .body-link.body-link {
			margin-left: 0;
			padding-left: 0;
		}
	}
	@media( max-width: 991px ) {
		.slider-controls {
			display: flex;
			flex-wrap: wrap;
		}
		.header {
			margin-bottom: 3px;
		}
	}
}