.header-top {
	padding: 25px 0 12px 0;
}

.header-bottom {
	padding: 12px 0 25px 0;
}

.header-bottom {
	.nav-link {
		text-transform: uppercase;
	}
}

.nav-link {
	color: $gray-1;
	letter-spacing: 1px;
	transition: all 120ms ease-in-out;
	font-size: $font-size-5;
	padding: 4px 15px;
	font-family: $roboto;
	display: inline-block;
	&:hover, &.active {
		text-decoration: none;
		color: $red;
	}

}

.logo.logo {
	padding-top: 20px;
	padding-left: 15px;
	img {
		max-width: 180px;
	}
}

.lang {
	display: inline-block;
}

.hide-demand-button .modal-trigger {
	display: none;
}

.lang .nav-link:nth-child(2) {
	padding-left: 0;
}


.toggler {
	display: none;
}

.scroll {
	margin-left: auto;
} 

.navbar-toggle {
	padding: 15px;
	cursor: pointer;
	position: relative;
	z-index: 12;
	margin-right: 20px;
	margin-top: 7px;
	div {
		width: 35px;
		height: 3px;
		margin: 4px 0;
		border-radius: 1px;
		background-color: $gray-1;
		&:nth-child(2) {
			width: 30px;
			transition: width .2s linear;
		}
	}
	&:hover div:nth-child(2) {
		width: 35px;
	}
}

@media(max-width: 1319px) {
	.header-top {
		display: none;
	}
	.nav, .lang {
		display: none;
	}
	.header-bottom {
		padding: 5px 0;
	}
	.navbar-toggle {
		display: inline-block;
	}
	.toggler {
		display: block;
	}

}

@media(max-width: 767px) {
	.logo {
		text-align: left;
		img {
			max-width: 200px;
		}
	}
}

@media(max-width: 767px) {
	.logo img {
		max-width: 160px;
	}
}


.sticky {
	position: relative;
	z-index: 9;
}

.sticky.fixed {
	position: fixed;
	width: 100%;
	background-color: white;
	z-index: 9;
	box-shadow: 0 1px 3px 0 rgb(220, 220, 220);
	animation-name: sticky;
	animation-duration: .3s;
	.header-top {
		display: none;
	}
	@media( min-width: 1320px ) {
		.modal-trigger {
			display: inline;
		}
	}
}

.sticky-placeholder {
	display: none;
}

.keep {
	transition: all .33s cubic-bezier(.685,.0473,.346,1);
	transform: translate3d(-260px,0,0);
}

@keyframes sticky {
	0% { transform: translateY( -100% ); }
	100% { transform: translateY( 0 ); }
}

.navbar-toggle .icon-bar {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

.navbar-toggle .icon-bar:nth-of-type(1) {
	-webkit-transform: rotate(41deg);
	-ms-transform: rotate(41deg);
	transform: rotate(41deg);
	-webkit-transform-origin: 18% 10%;
	-ms-transform-origin: 18% 10%;
	transform-origin: 18% 10%;
}

.navbar-toggle .icon-bar:nth-of-type(2) {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggle .icon-bar:nth-of-type(3) {
	-webkit-transform: rotate(-41deg);
	-ms-transform: rotate(-41deg);
	transform: rotate(-41deg);
	-webkit-transform-origin: 18% 90%;
	-ms-transform-origin: 18% 90%;
	transform-origin: 18% 90%;
}

/* ANIMATED  COLLAPSED */
.navbar-toggle.collapsed .icon-bar:nth-of-type(1) {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
}

.navbar-toggle.collapsed .icon-bar:nth-of-type(2) {
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggle.collapsed .icon-bar:nth-of-type(3) {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
}