@font-face {
	font-family: Proxima;
	src: url("/fonts/FontsFree-Net-Proxima-Nova-Thin.otf") format("opentype");
}

@font-face {
	font-family: Proxima;
	font-weight: bold;
	src: url("/fonts/FontsFree-Net-Proxima-Nova-Bold.otf") format("opentype");
}

// Font family
$roboto: 'Roboto', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$proxima: Proxima, sans-serif;

$font-family-base:
$roboto,
// Safari for OS X and iOS (San Francisco)
-apple-system,
// Chrome < 56 for OS X (San Francisco)
BlinkMacSystemFont,
// Windows
"Segoe UI",
// Android
"Roboto",
// Basic web fallback
"Helvetica Neue", Arial, sans-serif,
// Emoji fonts
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;


$font-size-base : 1rem;
$font-weight-base : 300;
$font-weight-bolder: 700;
$line-height-base : 1.5rem;

$body-bg : #fff;

$headings-margin-bottom:15px;
$paragraph-margin-bottom:12px;

$link-color:#ff0000;
$link-decoration:none;
$link-hover-color:#e90d1e;
$link-hover-decoration:underline;



body {
	font-family: $roboto;
}

p {
	text-align: justify;
}


// Font sizes
$font-size-1: 3.6rem;
$font-size-2: 1.375rem;
$font-size-3: 1.1rem;
$font-size-4: 1rem;
$font-size-5: .85rem;


// Colors
$red: #f43544;
$gray-1: darken(#79787d, 7);
$gray-2: darken(#d4d2d2, 15);
$gray-3: darken(#666, 15);
$gray-4: darken(#d3d3d5, 15);


$body-color : lighten($gray-1, 10);


// Buttons
.btn {
	font-family: $roboto;
	border: none;
	outline: none !important;
	padding: 11px 14px;
	font-size: $font-size-5;
	transition: all ease-in-out 120ms;
}

.btn-red {
	background-color: $red;
	color: white;
	&:hover {
		background-color: darken($red, 10);
	}
}

.btn-gray {
	background-color: transparent;
	border: 1px solid $gray-1;
	color: $gray-1;
	&:hover {
		border: 1px solid darken($gray-1, 15);
		color: darken($gray-1, 15);
	}
}

.hide-xl-down {
	@media( max-width: 1319px ) {
		display: none !important;
	}
}

.show-xl-down {
	display: none;
	@media( max-width: 1319px ) {
		display: block !important;
	}
}


.first-down-md {
	@media( max-width: 1319px ) {
		order: -1;
	}
}

.align-bottom {
	display: flex;
	flex-direction: column-reverse;
}

// Utils
.pt-0 {
	padding-top: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.p-1 {
	padding: 1rem !important;
}

.pt-1 {
	padding-top: 1rem !important;
}


.pb-1 {
	padding-bottom: 1rem !important;
}

.pl-1 {
	padding-left: 1rem !important;
}

.pr-1 {
	padding-right: 1rem !important;
}

.p-2 {
	padding: 2rem !important;
}

.pt-2 {
	padding-top: 2rem !important;
}


.pb-2 {
	padding-bottom: 2rem !important;
}

.pl-2 {
	padding-left: 2rem !important;
}

.pr-2 {
	padding-right: 2rem !important;
}

.py-2 {
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
}

.px-1 {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}

.px-2 {
	padding-left: 2rem !important;
	padding-right: 2rem !important;
}

.py-3 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.py-4 {
	padding-top: 4rem !important;
	padding-bottom: 4rem !important;
}

.py-0 {
	padding-top: 0rem !important;
	padding-bottom: 0rem !important;
}

.text-red {
	color: $red !important;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.ml-auto {
	margin-left: auto;
}

.mr-auto {
	margin-right: auto;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.d-flex {
	display: flex;
}

.oy-hidden {
	overflow-y: hidden;
}

.bg-footer {
	background-color: #fafafa;
}

.f-left {
	float: left;
}

.f-right {
	float: right;
}

.justify-content-center {
	justify-content: center;
}

.b-top {
	border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.b-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.b-left {
	border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.b-right {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.align-center {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.list-unstyled {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.d-none {
	display: none !important;
}

.order-xl-down-first {
	@media( max-width: 1319px ) {
		order: -1;
	}
}

.d-block {
	display: block;
}