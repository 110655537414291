#about-us {
	.body-link {
		text-align: right;
		display: block;
		margin-left: auto;
	}
	p {
		margin-bottom: 20px;
	}
	.g-title.g-title {
		padding-left: 0;
	}
}
video:focus {
	outline: none;
}
#video-section {
	position: relative;
	height: 500px;
	@media( max-width: 1200px ) {
		height: 350px;
	}
	@media( max-width: 600px ) {
		height: 250px;
	}
	@media( max-width: 400px ) {
		height: 200px;
	}
	.video-buttons {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
	button {
		cursor: pointer;
		background-color: transparent;
		border: none;
		border-radius: 100%;
		width: 30px;
		height: 30px;
		color: #666;
		border: 2px solid #666;
		display: flex;
		justify-content: center;
		display: inline-flex;
		margin-right: 5px;
		i {
			align-self: center;
			&:before {
				color: #666;
			}
		}
	}
	#mute-unmute {
		&:focus {
			outline: none;
		}
		&.active i:before {
			content: "\e904";
		}
	}
	#play-pause {
		&:focus {
			outline: none;
		}
		&.active i {
			&:before {
				content: "\e906";
			}
		}
	}
}