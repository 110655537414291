.bar-button {
	text-transform: uppercase;
	color: $red;
	font-weight: bold;
	font-size: $font-size-5;
	border: 0;
	cursor: pointer;
	background-color: transparent;
	&:focus {
		outline: none;
	}
	&:hover {
		color: darken($red, 10);
		text-decoration: none;
	}
}

.bar-button:before {
	content: "|";
	padding-right: 8px;
	position: relative;
	top: -2px;
}

.about-img {
	background: #fff url("../img/home-about.jpg") no-repeat center;
	background-size: cover;
	width: 100%;
	height: 400px;
}

.about-logo {
	background-color: #79787d;
	display: flex;
	justify-content: center;
	padding: 50px 0;
	img {
		align-self: center;
		max-width: 90%;
	}
}

#about {
	padding: 90px 0;
}


.g-title, .g-subtitle {
	letter-spacing: 1px;
}

.g-title {
	font-size: $font-size-1;
	font-weight: bold;
	font-family: $proxima;
	margin-bottom: 35px;
	line-height: 70px;
	color: #d4d2d2;
	margin-left: -3px;
}

.g-subtitle {
	font-family: $proxima;
	font-weight: 400;
	font-size: $font-size-2;
	text-transform: uppercase;
	line-height: 25px;
	letter-spacing: 1px;
	margin-bottom: 15px;
}

#about .bar-button {
	font-size: $font-size-5;
}

.about-text {
	padding: 0 25px;
	max-width: 500px;
}

@media (max-width: 1319px) {
	.about-text {
		max-width: none;
	}
	#about {
		padding-top: 0;
		padding-bottom: 45px;
	}
}

@media(max-width: 767px) {
	.about-logo {
		display: none;
	}
}



@media(max-width: 991px) {
	.g-title {
		font-size: 2.8rem;
		line-height: 2.8rem;
		margin-bottom: 15px;
	}
}

@media(max-width: 768px) {
	.g-title.g-title {
		padding-left: 16px;
	}
}