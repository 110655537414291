.menu {
	display: none;
}

@media( max-width: 1319px ) {
	.menu {
		padding-top: 100px;
		box-shadow: -1px 0 3px 0 rgb(220, 220, 220);
		display: block;
		position: fixed;
		width: 260px;
		height: 100%;
		right: 0;
		top: 0;
		background-color: white;
		z-index: 9;
		transform: translateX(100%);
		transition: all .5s cubic-bezier(.685,.0473,.346,1);
		.scroll {
			@media( max-width: 991px ) {
				max-height: calc(100vh - 100px);
				overflow-y: auto;
			}
		}
		.scroll >.nav-link {
			display: block;
			font-size: 18px;
			padding: 7px 36px;
		}
		.logo {
			display: block;
			margin: 20px 0 10px 0;
		}
		.lang-mobile {
			padding-right: 15px;
			margin: 10px 15px 0 0;
			.nav-link {
				font-size: 16px;
				text-transform: uppercase;
				float: right;
				padding: 5px;
			}
		}
	}
	.menu.show {
		transform: translateX(0);
	}
}






