#products {
	margin: 60px 0;
	.g-title {
		margin-bottom: 75px;
	}
}

#shop .body-link {
	display: block;
}

#portfolio {
	padding: 100px 0;
}

.body-link {
	display: inline-block;
	user-select: none;
	padding: 6px 10px;
	font-family: $proxima;
	font-size: .95rem;
	font-weight: bold;
	margin: 0 22px;
	transition: all 120ms ease-in-out;
	background-color: transparent;
	border: none;
	color: lighten($gray-3, 40);
	&.scat {
		font-size: .9rem;
		line-height: 1rem;
		padding-right: 20px;
		position: relative;
		&:before {
			content: "";
			display: block;
			width: 6px;
			height: 20px;
			border-right: 1px solid #999;
			border-bottom: 1px solid #999;
			position: absolute;
			right: 12px;
			top: -6px;
		}
		@media( max-width: 991px ) and ( min-width: 768px ) {
			font-size: .95rem;
			line-height: 1.4;
			&:before {
				display: none;
			}
		}
	}
	i {
		color: lighten($gray-3, 40);
		transition: all 120ms ease-in-out;

	}
	&:focus {
		outline: none;
	}
	&:not(.prod) {
		text-transform: uppercase;
	}
	&:hover, &.active {
		text-decoration: none;	
		color: $gray-1;
		i {
			color: $gray-1;
		}
	}
	@media( max-width: 1150px ) {
		margin-left: 9px;
		margin-right: 9px;
		&.prod {
			margin: 6px 0;
			padding-left: 0;
		}
	}
}

@media(max-width: 1319px) {
	.body-link.prod {
		float: left;
	}
	#products {
		margin: 0;
	}
	#products .g-title {
		margin-bottom: 10px;
	}
	.products-wrap.products-wrap {
		padding-top: 0;
	}
}

.product-item {
	position: relative;
	margin-bottom: 30px;
	img {
		width: 100%;
		max-width: 100%;
	}
	.labels {
		position: absolute;
		bottom: 30px;
		right: 0;
		text-align: right;
		.label-name {
			transform: translateX(40px);
		}
	}
}


.btn-red, .label-name {
	text-transform: uppercase;
	font-size: $font-size-5;
	letter-spacing: 1px;
}

.btn-red {
	background-color: $red;
	color: white !important;
	transition: all 120ms ease-in-out;
	padding: 12px 15px;
	text-decoration: none;
	&:hover {
		background-color: darken($red, 10);
		text-decoration: none;
	}
}

.label-name {
	font-weight: bold;
	padding: 4px 15px;
	min-height: 44px;
	margin-bottom: 18px;
	background-color: white;
	display: flex;
	span {
		align-self: center;
		line-height: 16px;
	}
}

.products-wrap {
	padding-top: 60px;
}

.product-item {
	@media( max-width: 767px ) {
		margin: auto;
		& .labels .label-name {
			transform: translate(0);
		}
	}
}

@media( max-width: 991px ) {
	#portfolio {
		padding: 30px 0;
	}
	.products-wrap {
		padding-top: 30px;
	}
}

.tab {
	display: none;
	&.active {
		display: block;
	}
}

.product-item .image {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	display: block;
	padding-bottom: 70%;
}

.solutii {
	padding-top: 0 !important;
	.body-link {
		margin: 0;
		text-align: center;
		margin-bottom: 20px;
		img {
			width: 180px;
			display: block;
			margin: 5px auto;
			@media( max-width: 850px ) and ( min-width: 768px ) {
				width: 160px;
			}
		}
	}
}

.portfolio-item {
	margin: 30px 0;
	background-color: lighten( #ECE9E9, 5 );
	@media( max-width: 700px ) {
		padding: 20px 0;
		.row {
			flex-direction: column;
			.col-5 {
				flex: 0 0 100%;
				max-width: 500px;
				margin: 20px auto;
			}
			.img {
				padding-bottom: 80%;
			}
		}
	}
	h2 {
		margin-bottom: 10px;
	}
	.g-subtitle {
		margin-bottom: 5px;
		font-size: 1rem;
		p {
			margin-bottom: 0;
		}
	}
	p, div {
		text-align: center;
	}
	.img {
		background-size: cover;
		padding-bottom: 100%;
	}
	.separator {
		height: 50px;
		width: 1px;
		background-color: darken( #ECE9E9, 20 );
		margin: 20px auto;
	}
	.title {
		font-family: $proxima;
		text-transform: uppercase;
	}
}

.p-text {
	display: flex;
	justify-content: center;
	height: 100%;
	text-align: center;
	.text-wrap {
		align-self: center;
		padding: 10px;
	}
}

#project .g-title {
	font-size: 2.7rem;
	line-height: 1;
	@media( max-width: 991px ) {
		font-size: 2rem;
	}
}