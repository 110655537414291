#catalogs {
	padding: 45px 0;
	.g-title {
		margin-bottom: 60px;
	}
	.product-item .labels {
		top: 30px;
		bottom: auto;
	} 
	.bar-button {
		padding: 8px 15px;
		margin: 20px 5px	;
	}
	@media (max-width: 1319px) {
		.align-bottom {
			display: block;
			padding-top: 60px;
		}
	}
	.label-name {
		transform: translate(0);
	}
}
@media (max-width: 767px) {
	#products, #portfolio, #catalogs, #product {
		.labels {
			transform: none !important;
			text-align: right;
		}
	}
	#catalogs#catalogs .text {
		max-width: none;
	}

}

@media (max-width: 991px) {
	#catalogs {
		padding: 0;
		.g-title {
			margin-bottom: 25px;
		}
	}
	.align-bottom.align-bottom {
		padding-top: 15px;
	}
}