// Variables
@import 'variables';

// Components
@import 'components/reboot';
@import 'components/grid';
@import 'components/layout';
@import 'components/header';
@import 'components/slider';
@import 'components/icons';
@import 'components/benefits';
@import 'components/home-about';
@import 'components/s-slider';
@import 'components/products';
@import 'components/demand';
@import 'components/team';
@import 'components/catalogs';
@import 'components/single-product';
@import 'components/mobile-menu';
@import 'components/contact';
@import 'components/breadcrumb';
@import 'components/animate';
@import 'components/footer';
@import 'components/indexShop';
@import 'components/documentatie';
@import 'components/single-project';
@import 'components/dropdown';
@import 'components/about';
@import 'components/tiny-slider-style';

// lazy loading animation
.lazy {
  opacity: 0;
  will-change: opacity;
}

.loaded {
  animation: fadeIn 0.5s ease-in;
  animation-fill-mode: forwards;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.text-muted {
    color: #6c757d!important;
}
.fixed-bottom {
    bottom: 0;
}
.fixed-bottom, .fixed-top {
    position: fixed;
    left: 0;
    z-index: 1030;
}
.bg-white {
    background-color: #fff!important;
}
.alert {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    }
// webfont caching load visiblility animation
// h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h6,.h5,.title {
// 	opacity:0;
// 	will-change:opacity;
// }
// .wf-active {
// 	h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h6,.h5,.title {
// 		animation:fadeIn 0.5s ease-in;
// 		animation-fill-mode: forwards;
// 	}
// }